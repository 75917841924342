/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { darken } from 'polished';
import Link from './link';
import UnbulletedList from './unbulleted-list';
import CaseStudyNavWithQuery from './case-study-nav';
import theme from '../gatsby-plugin-theme-ui';

export const PrimaryNav = ({ navItems, menuOpen, setMenuOpen }) => (
  <nav
    sx={{
      display: [menuOpen ? 'block' : 'none', 'block'],
      width: ['100%', null, 'auto'],
      mt: [null, -4, 0],
      lineHeight: '2.1',
    }}
  >
    <UnbulletedList
      sx={{
        display: ['block', 'flex'],
        justifyContent: 'flex-end',
      }}
    >
      {navItems.sort((a, b) => (a.weight > b.weight ? 1 : -1)).map(({ title, url }) => (
        <li
          key={url}
          sx={{
            position: 'relative',
            '&:hover': {
              '> a': {
                bg: darken(0.1, theme.colors.background),
              },
              '> div': {
                visibility: 'visible',
              },
            },
          }}
        >
          <React.Fragment>
            <Link
              to={url}
              activeClassName="active"
              sx={{
                display: 'block',
                textDecoration: 'none',
                color: 'text',
                py: 2,
                px: 3,
                textAlign: 'right',
                fontSize: [1, 0],
                '&:active, &.active': {
                  bg: ['backgroundHeader', null, 'background'],
                },
                '&:hover': {
                  bg: darken(0.1, theme.colors.background),
                },
              }}
              onClick={() => setMenuOpen(false)}
            >
              {title}
            </Link>
            {url === '/work' && (
              <CaseStudyNavWithQuery setMenuOpen={setMenuOpen} />
            )}
          </React.Fragment>
        </li>
      ))}
    </UnbulletedList>
  </nav>
);

PrimaryNav.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    weight: PropTypes.number.isRequired,
  })).isRequired,
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
};

const PrimaryNavWithQuery = (props) => {
  const { dataJson } = useStaticQuery(
    graphql`
      query {
        dataJson {
          links {
            title
            url
            weight
          }
        }
      }
    `,
  );

  return <PrimaryNav navItems={dataJson ? dataJson.links : []} {...props} />;
};

export default PrimaryNavWithQuery;
