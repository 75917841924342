import groupBy from './groupBy';

const parseCaseStudyNodes = (caseStudies) => {
  const caseStudyArr = caseStudies.edges
    .map(({ node: { fields, frontmatter } }) => ({ ...fields, ...frontmatter }));

  const industries = caseStudyArr.reduce((arr, c) => [...arr, ...c.industries || ''], []);
  const uniqueIndustries = [...new Set(industries)];
  const groupedByClient = groupBy(caseStudyArr, 'client');

  return { caseStudyArr, industries: uniqueIndustries, groupedByClient };
};

export default parseCaseStudyNodes;
