/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const SmartImage = ({ src, ...other }) => {
  if (src.childImageSharp) {
    return <Img fluid={src.childImageSharp.fluid} {...other} />;
  }
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={src.publicURL ? src.publicURL : src} {...other} />;
};

SmartImage.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }),
    }),
  ]).isRequired,
};

export default SmartImage;
