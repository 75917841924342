/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
import { jsx } from 'theme-ui';

const UnbulletedList = (props) => (
  <ul
    {...props}
    sx={{
      listStyleType: 'none',
      m: 0,
      p: 0,
    }}
  />
);

export default UnbulletedList;
