import PropTypes from 'prop-types';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({
  children, to, activeClassName, partiallyActive, ...other
}) => {
  // any internal link will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // any file will have a dot in the url
  const file = /\.[0-9a-z]+$/i.test(to);

  if (internal && !file) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
};

Link.defaultProps = {
  activeClassName: null,
  partiallyActive: true,
};

export default Link;
