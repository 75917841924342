/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { darken } from 'polished';
import slugify from 'slugify';
import Link from './link';
import UnbulletedList from './unbulleted-list';
import theme from '../gatsby-plugin-theme-ui';
import parseCaseStudyNodes from '../utils/caseStudies';

export const CaseStudyNav = ({ clients, industries, setMenuOpen }) => (
  <Flex
    sx={{
      bg: darken(0.1, theme.colors.background),
      p: 3,
      lineHeight: 1.5,
      position: ['static', 'absolute'],
      zIndex: 2,
      right: 0,
      width: ['100%', 'max-content'],
      visibility: ['visible', 'hidden'],
    }}
  >
    <div sx={{ mr: 5 }}>
      <h2 sx={{ variant: 'text.smallcaps' }}>Client</h2>
      <UnbulletedList>
        {clients.sort().map((client) => (
          <li key={client}>
            <Link
              to={`/work#${slugify(client, { lower: true })}`}
              onClick={() => setMenuOpen(false)}
              sx={{ variant: 'text.navlink' }}
            >
              {client}
            </Link>
          </li>
        ))}
      </UnbulletedList>
    </div>
    <div>
      <h2 sx={{ variant: 'text.smallcaps' }}>Industry</h2>
      <UnbulletedList>
        {industries.sort().map((industry) => (
          <li key={industry}>
            <Link
              to={`/work?industry=${industry}`}
              state={{ industry }}
              onClick={() => setMenuOpen(false)}
              sx={{ variant: 'text.navlink' }}
            >
              {industry}
            </Link>
          </li>
        ))}
      </UnbulletedList>
    </div>
  </Flex>
);

CaseStudyNav.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.string),
  industries: PropTypes.arrayOf(PropTypes.string),
  setMenuOpen: PropTypes.func.isRequired,
};

CaseStudyNav.defaultProps = {
  clients: [],
  industries: [],
};

const CaseStudyNavWithQuery = ({ setMenuOpen, ...other }) => {
  const { caseStudies } = useStaticQuery(
    graphql`
      query {
        caseStudies: allMdx(
          filter: {frontmatter: {templateKey: {eq: "case-study"}}}
          sort: {fields: frontmatter___client}
        ) {
          edges {
            node {
              frontmatter {
                title
                weight
                client
                industries
              }
            }
          }
        }
      }
    `,
  );

  if (caseStudies) {
    const { industries, groupedByClient } = parseCaseStudyNodes(caseStudies);

    return (
      <CaseStudyNav
        industries={industries}
        clients={Object.keys(groupedByClient)}
        setMenuOpen={setMenuOpen}
        {...other}
      />
    );
  }
  return null;
};

CaseStudyNavWithQuery.propTypes = {
  setMenuOpen: PropTypes.func.isRequired,
};

export default CaseStudyNavWithQuery;
