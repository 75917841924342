const theme = require('./src/gatsby-plugin-theme-ui');

module.exports = {
  siteTitle: 'Westover Strategy, Inc.',
  siteTitleShort: 'WestoverStrategy',
  siteDescription: 'Bringing Clarity to Strategy. A Clear and Forthright Approach to Company Strategy',
  siteUrl: 'https://westoverstrategyinc.com/',
  themeColor: theme.colors.primary,
  backgroundColor: theme.colors.background,
  // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  pathPrefix: null,
  logo: 'static/uploads/swan.png',
  company: {
    type: 'LocalBusiness',
    name: 'Westover Strategy',
    legalName: 'Westover Strategy, Inc.',
    email: 'susanpb@westoverstrategyinc.com',
    sameAs: [
      'https://www.linkedin.com/company/westover-strategy-inc/',
    ],
  },
};
