import PropTypes from 'prop-types';
/** @jsx jsx */
import { jsx } from 'theme-ui';

const Burger = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="currentcolor"
    viewBox="0 0 24 24"
    sx={{
      display: 'block',
      margin: 0,
    }}
  >
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
);

Burger.propTypes = {
  size: PropTypes.string,
};

Burger.defaultProps = {
  size: '1em',
};

const MenuButton = ({ menuOpen, setMenuOpen, ...other }) => (
  <button
    title="Toggle Menu"
    type="button"
    {...other}
    sx={{
      fontFamily: 'inherit',
      fontSize: 24,
      color: 'inherit',
      bg: 'transparent',
      width: 32,
      height: 32,
      p: 1,
      m: 0,
      border: 0,
      appearance: 'none',
      ':focus': {
        outline: '2px solid',
      },
      display: ['block', 'none'],
    }}
    onClick={() => setMenuOpen(!menuOpen)}
  >
    <Burger />
  </button>
);

MenuButton.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
};

export default MenuButton;
