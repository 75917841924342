/** @jsx jsx */
import { jsx, Footer, Styled } from 'theme-ui';
import PropTypes from 'prop-types';
import { FaLinkedin, FaFacebookSquare } from 'react-icons/fa';

const FooterWrapper = ({ legalName }) => (
  <Footer
    sx={{
      my: 5,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 0,
      flexDirection: 'column',
    }}
  >
    <p sx={{ mb: 2, mt: 0 }}>
      <Styled.a href="https://www.linkedin.com/company/westover-strategy-inc/" title="LinkedIn" target="_blank">
        <FaLinkedin size="1.75em" />
      </Styled.a>
      <Styled.a href="https://www.facebook.com/Westover-Strategy-Inc-104018307689627/" title="Facebook" target="_blank">
        <FaFacebookSquare size="1.75em" />
      </Styled.a>
    </p>
    <p sx={{ m: 0 }}>
      &copy;&nbsp;
      {legalName}
    </p>
  </Footer>
);

FooterWrapper.propTypes = {
  legalName: PropTypes.string.isRequired,
};

export default FooterWrapper;
