/** @jsx jsx */
import { jsx, Header, Container } from 'theme-ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import PrimaryNavWithQuery from './primary-nav';
import MenuButton from './menu-button';
import swan from '../../static/uploads/swan.png';
import theme from '../gatsby-plugin-theme-ui';

const HeaderWrapper = ({ siteTitle }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundImage: [
          `linear-gradient(to bottom, ${theme.colors.backgroundHeader} 52px, ${theme.colors.background} 52px)`,
          `linear-gradient(to bottom, ${theme.colors.backgroundHeader} 125px, ${theme.colors.background} 125px)`,
        ],
      }}
    >
      <Container>
        <Header
          sx={{
            flexWrap: 'wrap',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <a
            href="/"
            sx={{
              display: 'inline-block',
              fontFamily: '"JensenRecut-Regular", serif',
              fontSize: [2, 5],
              lineHeight: '45px',
              textDecoration: 'none',
              color: 'primary',
              backgroundImage: `url(${swan})`,
              backgroundSize: ['60px', '127px'],
              backgroundPosition: ['10px 10px', '35px 35px', '70px 35px'],
              backgroundRepeat: 'no-repeat',
              padding: ['16px 0 20px 86px', '77px 0 58px 205px', '77px 0 58px 240px'],
            }}
          >
            {siteTitle}
          </a>
          <MenuButton menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <PrimaryNavWithQuery menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </Header>
      </Container>
    </div>
  );
};

HeaderWrapper.propTypes = {
  siteTitle: PropTypes.string.isRequired,
};

export default HeaderWrapper;
