/** @jsx jsx */
import { jsx, Styled, Main } from 'theme-ui';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Global } from '@emotion/core';
import Helmet from 'react-helmet';
import Header from './header';
import Footer from './footer';
import jensen from '../fonts/JensonRecut-Regular-webfont.woff2';

export const LayoutWrapper = ({ children, siteTitle, legalName }) => (
  <Styled.root>
    <Global
      styles={{
        body: {
          margin: 0,
        },
        '@font-face': {
          fontFamily: 'JensenRecut-Regular',
          fontWeight: 400,
          fontStyle: 'normal',
          fontDisplay: 'swap',
          src: `url(${jensen}) format("woff2")`,
        },
      }}
    />
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    </Helmet>
    <Header siteTitle={siteTitle} />
    <Main>
      {children}
    </Main>
    <Footer legalName={legalName} />
  </Styled.root>
);

LayoutWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  siteTitle: PropTypes.string.isRequired,
  legalName: PropTypes.string.isRequired,
};

const LayoutWithQuery = (props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            company {
              legalName
            }
          }
        }
      }
    `,
  );

  return (
    <LayoutWrapper
      siteTitle={site.siteMetadata.siteTitle}
      legalName={site.siteMetadata.company.legalName}
      {...props}
    />
  );
};

export default LayoutWithQuery;
